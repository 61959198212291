import cn from 'classnames';
import { useContext, useMemo } from 'react';
import type { ButtonHTMLAttributes } from 'react';

import { CustomStylesCtx } from '../custom-styles';
import { Loader } from '../loader';
import { CssUiComponent } from '../survey-custom';

import css from './button.css';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    color?: 'blue' | 'white' | 'transparent';
    fluid?: boolean;
    bordered?: boolean;
    loading?: boolean;
    className?: string;
    darkText?: boolean;
    title?: string;
    disabled?: boolean;
    slim?: boolean;
    responsive?: boolean;
    label?: string;
    borderColor?: 'blue';
    size?: 'small' | 'medium' | 'big';
}

export const Button: FC<ButtonProps> = ({
    bordered,
    children,
    className,
    color,
    darkText,
    disabled,
    fluid,
    label,
    loading,
    slim,
    responsive,
    type = 'button',
    borderColor,
    size,
    ...props
}) => {
    const {
        button: {
            root: { backgroundColor = '', color: buttonColor = '', width = '', height = '', borderRadius = '' } = {}
        }
    } = useContext(CustomStylesCtx);

    const parsedBackgroundColor = useMemo(() => {
        try {
            return JSON.parse(backgroundColor);
        } catch (e) {
            return backgroundColor;
        }
    }, [backgroundColor]);

    const style = useMemo(() => {
        let backgroundColor;
        let background;

        if (fluid && !bordered) {
            if (Array.isArray(parsedBackgroundColor)) {
                if (parsedBackgroundColor.length === 1) {
                    backgroundColor = parsedBackgroundColor[0];
                } else if (parsedBackgroundColor.length > 1) {
                    background = `linear-gradient(90deg, ${parsedBackgroundColor[0]}, ${parsedBackgroundColor[1]}, ${parsedBackgroundColor[2]})`;
                }
            } else if (typeof parsedBackgroundColor === 'string') {
                backgroundColor = parsedBackgroundColor;
            }
        }

        return {
            background,
            backgroundColor,
            color: fluid && bordered ? buttonColor : '',
            width: `${width}px`,
            height: `${height}px`,
            borderRadius: `${borderRadius}px`
        };
    }, [parsedBackgroundColor, bordered, buttonColor, fluid, width, height, borderRadius]);

    return (
        <button
            disabled={loading || disabled}
            style={style}
            type={type}
            className={cn(
                CssUiComponent.BUTTON,
                css.button,
                {
                    [css[color]]: color,
                    [css.slim]: slim,
                    [css.fluid]: fluid,
                    [css.disabled]: disabled,
                    [css.bordered]: bordered,
                    [css.loading]: loading,
                    [css.darkText]: darkText,
                    [css.responsive]: responsive,
                    [css[`borderColor_${borderColor}`]]: borderColor
                },
                className
            )}
            {...props}
        >
            <Loader inline className={cn(CssUiComponent.BUTTON_LOADER, css.loader)} visible={loading} />
            <span className={cn(CssUiComponent.BUTTON_TEXT, css.contents)}>{label || children}</span>
        </button>
    );
};
